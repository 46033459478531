@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'public/fonts/fonts';

// prevent browser-specific tap highlights on e.g. all buttons
// (so that they do not shortly turn blue when being clicked)
// see https://github.com/tailwindlabs/tailwindcss/pull/9050#issuecomment-1208468878
@layer base {
  html {
    -webkit-tap-highlight-color: transparent !important;
  }
}

@layer utilities {
  .position-unset {
    position: unset;
  }
}

body {
  @apply bg-white text-base font-base;
}

.hyphens-none {
  hyphens: none;
}

.hyphens-manual {
  hyphens: manual;
}

.hyphens-auto {
  hyphens: auto;
}

// Class to remove tailwind preflight
.imported {
  blockquote {
    display: block;
    margin: 1em 40px;
  }

  dd {
    display: block;
    margin-left: 40px;
  }

  dl {
    display: block;
    margin: 1em 0;
  }

  h1 {
    display: block;
    font-size: 2em;
    margin: 0.67em 0;
    font-weight: bold;
  }

  h2 {
    display: block;
    font-size: 1.5em;
    margin: 0.83em 0;
    font-weight: bold;
  }

  h3 {
    display: block;
    font-size: 1.17em;
    margin: 1em 0;
    font-weight: bold;
  }

  h4 {
    display: block;
    margin: 1.33em 0;
    font-weight: bold;
  }

  h5 {
    display: block;
    font-size: .83em;
    margin: 1.67em 0;
    font-weight: bold;
  }

  h6 {
    display: block;
    font-size: .67em;
    margin: 2.33em 0;
    font-weight: bold;
  }

  hr {
    display: block;
    margin: 0.5em auto;
    border-style: inset;
    border-width: 1px;
  }

  figure {
    display: block;
    margin: 1em 40px;
  }

  img {
    display: inline-block;
    max-width: unset;
    height: unset;
    vertical-align: unset;
    width: 100%;
  }

  video {
    display: unset;
    vertical-align: unset;
    max-width: unset;
    height: unset;
  }

  svg,
  canvas,
  audio,
  iframe,
  embed,
  object {
    display: unset;
    vertical-align: unset;
  }

  iframe[seamless] {
    display: block;
  }

  iframe:focus {
    outline: none;
  }

  embed:focus {
    outline: none;
  }

  object:focus {
    outline: none;
  }

  ol {
    display: block;
    list-style-type: decimal;
    margin: 1em 0;
    padding-left: 40px;
  }

  p {
    display: block;
    margin: 1em 0;
  }

  pre {
    display: block;
    font-family: monospace;
    white-space: pre;
    margin: 1em 0;
  }

  script {
    display: none;
  }

  ul {
    display: block;
    list-style-type: disc;
    margin: 1em 0 1em;
    padding-left: 40px;
  }

  *,
  ::before,
  ::after {
    border-width: unset;
    border-style: unset;
    border-color: unset;
  }

  .google-map * {
    border-style: unset;
  }

  button:focus {
    outline: unset;
  }

  a {
    text-decoration: underline;
  }

}

*:not(.force-scrollbar) {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  /* For Webkit-based browsers (Chrome, Safari and Opera) */
  &::-webkit-scrollbar {
    display: none;
  }
}

.force-scrollbar {
  @media only screen and (max-width: 1024px) {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    /* For Webkit-based browsers (Chrome, Safari and Opera) */
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.question-time-mask {
  clip-path: inset(0 calc(100% - var(--maskWidth, 0)) 0 0);
}

.dotted-background {
  background-image: radial-gradient(circle at 2px 2px, #95C11E80 2px, transparent 0);
  mask-image: radial-gradient(circle at 50vw 0vh , black 0%, rgba(0, 0, 0, 0) 50%);
  background-size: 25px 25px;
}

/** für Impressum und Datenschutz */
.standalone-page-style {
  a, button {
    color: #0070f3;
    text-decoration: underline;
  }
  p {
    margin-top: 0.5rem;
  }
  h1 {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 1rem;
  }
  h2 {
    font-size: 1.25rem;
    font-weight: 700;
    margin-top: 2rem;
  }
  h3 {
    font-weight: 600;
    margin-top: 2rem;
  }
  #trackVisits {
    margin-right: 0.5rem;
  }
}
.show-bullet-points {
  ul {
    margin-left: 1.5rem;
    list-style: disc;
  }
  .numberedList {
    margin-left: 1.5rem;
    list-style: decimal;
  }
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
