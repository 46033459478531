@font-face {
    font-family: 'icomoon';
    src: url('./fonts/icomoon.ttf?nvgt9n') format('truetype'),
    url('./fonts/icomoon.woff?nvgt9n') format('woff'),
    url('./fonts/icomoon.svg?nvgt9n#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^='icon-'], [class*=' icon-'] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-close:before {
    content: "\e917";
}

.icon-demote:before {
    content: "\e923";
}

.icon-promote:before {
    content: "\e924";
}

.icon-sun:before {
    content: "\e925";
}

.icon-body:before {
    content: "\e920";
}

.icon-jewelry:before {
    content: "\e91f";
}

.icon-headgear:before {
    content: "\e922";
}

.icon-eyebrow:before {
    content: "\e91a";
}

.icon-eye:before {
    content: "\e91d";
}

.icon-beard:before {
    content: "\e91e";
}

.icon-hair:before {
    content: "\e921";
}

.icon-arrow-left:before {
    content: "\e901";
}

.icon-arrow-right:before {
    content: "\e902";
}

.icon-book:before {
    content: "\e903";
}

.icon-check-pur:before {
    content: "\e904";
}

.icon-check:before {
    content: "\e905";
}

.icon-copy:before {
    content: "\e907";
}

.icon-delete:before {
    content: "\e908";
}

.icon-exit:before {
    content: "\e909";
}

.icon-gender:before {
    content: "\e90a";
}

.icon-help-pur:before {
    content: "\e90c";
}

.icon-help:before {
    content: "\e90d";
}

.icon-home:before {
    content: "\e90e";
}

.icon-most-played:before {
    content: "\e90f";
}

.icon-points:before {
    content: "\e910";
}

.icon-profile:before {
    content: "\e911";
}

.icon-quiz:before {
    content: "\e912";
}

.icon-search:before {
    content: "\e913";
}

.icon-settings:before {
    content: "\e914";
}

.icon-teams:before {
    content: "\e915";
}

.icon-trophy:before {
    content: "\e916";
}

.icon-shirt:before {
    content: "\e906";
}

.icon-_hair:before {
    content: "\e90b";
}

.icon-_body:before {
    content: "\e918";
}

.icon-_headgear:before {
    content: "\e900";
}

.icon-_eyebrow:before {
    content: "\e919";
}

.icon-_beard:before {
    content: "\e91b";
}

.icon-_jewelry:before {
    content: "\e91c";
}
