@import '/public/fonts/icomoon/style.css';

$weights: (
        100: 'Thin',
        200: 'ExtraLight',
        300: 'Light',
        400: 'Regular',
        500: 'Medium',
        600: 'SemiBold',
        700: 'Bold',
        800: 'ExtraBold',
        900: 'Black'
);
$styles: (normal: '', italic: 'Italic');

@each $family in 'FiraSans', 'FiraSansCondensed' {
  @each $weight, $fileWeight in $weights {
    @each $style, $fileStyle in $styles {
      @font-face {
        font-family: $family;
        font-style: $style;
        font-weight: $weight;
        font-display: swap;
        src: url('./#{$family}-#{$fileWeight}#{$fileStyle}.ttf') format('truetype');
      }
    }
  }
}
